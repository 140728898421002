import { Card, CardContent, CardMedia, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, Skeleton } from '@mui/material';
import { deleteAssignedPremiumMovies } from 'api/customers';
import { getAssignedPremiumMovies } from 'api/customers';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react'

const PremiumsDialog = ({ openPremiumDialog, setOpenPremiumDialog, customerInfo, setShowNotification, setNotificationContent }) => {
    const [assignedMovies, setAssignedMovies] = useState([]);
    const [movieLoading, setMovieLoading] = useState(false);
    const [selectedMovies, setSelectedMovies] = useState([]);

    useEffect(() => {
        if (openPremiumDialog) {
            setMovieLoading(true);

            getAssignedPremiumMovies(customerInfo.customer_id)
                .then(fetchedMovies => setAssignedMovies(fetchedMovies.data))
                .catch(error => console.error("Failed to fetch movies: ", error))
                .finally(() => setMovieLoading(false));
        }
    }, [openPremiumDialog])

    const handleMovieToggle = (movie_id) => {
        setSelectedMovies(selectedIds => {
            const isSelected = selectedIds.some(id => id === movie_id);
            if (isSelected)
                return selectedIds.filter(id => id !== movie_id);
            else
                return [...selectedIds, movie_id];
        })
    }

    const deleteAssignedMovies = async () => {
        const res = await deleteAssignedPremiumMovies(customerInfo.customer_id, selectedMovies);
        if (res.status === 200) {
            setNotificationContent({
                color: "success",
                content: "Successfully deleted",
                dateTime: "Just Now",
                icon: "check"
            });
        }
        else {
            setNotificationContent({
                color: "error",
                content: response.data.message,
                dateTime: "Just Now",
                icon: "warning"
            });
        }

        setShowNotification(true);
        setSelectedMovies([]);
        setOpenPremiumDialog(false);
    }

    return (
        <Dialog
            open={openPremiumDialog}
            onClose={() => setOpenPremiumDialog(false)}
            aria-labelledby='premium-edit-dialog'
            maxWidth="lg"
            fullWidth
        >
            <DialogTitle>Assigned Premiums</DialogTitle>
            <DialogContent>
                <div style={{ display: "flex", flexWrap: "wrap", gap: "16px" }}>
                    <div style={{ display: "flex", gap: "8px" }}>
                        <MDTypography variant="body2" gutterBottom display="inline">
                            Username:
                        </MDTypography>
                        <MDTypography
                            variant="body2"
                            color="info"
                            fontWeight="bold"
                            display="inline"
                        >
                            {customerInfo.username}
                        </MDTypography>
                    </div>
                    <div style={{ display: "flex", gap: "8px" }}>
                        <MDTypography variant="body2" gutterBottom display="inline">
                            Total Limit:
                        </MDTypography>
                        <MDTypography
                            variant="body2"
                            color="info"
                            fontWeight="bold"
                            display="inline"
                        >
                            {customerInfo.tier.tier_limit}
                        </MDTypography>
                    </div>
                    <div style={{ display: "flex", gap: "8px" }}>
                        <MDTypography variant="body2" gutterBottom display="inline">
                            Current Movie Number:
                        </MDTypography>
                        <MDTypography
                            variant="body2"
                            color="info"
                            fontWeight="bold"
                            display="inline"
                        >
                            {customerInfo.currentSession.current_index}
                        </MDTypography>
                    </div>
                </div>

                <Grid container spacing={2}>
                    {movieLoading && assignedMovies.length > 0 ?
                        Array.from(new Array(6)).map((_, index) => (
                            <Grid item xs={12} sm={6} md={4} key={index}>
                                <Skeleton
                                    animation="wave"
                                    variant="rectangular"
                                    height={140}
                                />
                                <Skeleton animation="wave" />
                                <Skeleton animation="wave" width="60%" />
                            </Grid>
                        )) : assignedMovies.map(movie => <Grid item xs={12} md={4} key={movie.movie_id}>
                            <Card>
                                <CardMedia
                                    component="img"
                                    height="140"
                                    image={movie.movie_image}
                                    alt={movie.movie_name}
                                />
                                <CardContent>
                                    <MDTypography gutterBottom variant="h6" component="div">
                                        {movie.movie_name}
                                    </MDTypography>
                                    <MDTypography variant="body2" sx={{ mb: 1 }}>
                                        {movie.movie_description}
                                    </MDTypography>
                                    <MDTypography variant="body1" sx={{ mb: 1 }}>
                                        ${parseFloat(movie.movie_price).toFixed(2)}
                                    </MDTypography>

                                    <MDTypography variant="body2" gutterBottom display="inline">
                                        Movie Index:
                                    </MDTypography>
                                    <MDTypography
                                        variant="body2"
                                        color="info"
                                        fontWeight="bold"
                                        display="inline"
                                    >
                                        {movie.premium_set.movie_index}
                                    </MDTypography>

                                    {customerInfo.currentSession.current_index < movie.premium_set.movie_index && (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={selectedMovies.some(id => id === movie.movie_id)}
                                                    onChange={() => handleMovieToggle(movie.movie_id)}
                                                />
                                            }
                                            label="Select"
                                        />
                                    )}
                                </CardContent>
                            </Card>
                        </Grid>)
                    }
                </Grid>
            </DialogContent>

            <DialogActions>
                <MDButton onClick={() => setOpenPremiumDialog(false)}>
                    Cancel
                </MDButton>

                <MDButton
                    onClick={deleteAssignedMovies}
                    variant="contained"
                    color="info"
                    disabled={selectedMovies.length === 0}
                >
                    Delete Assignment
                </MDButton>
            </DialogActions>
        </Dialog>
    )
}

PremiumsDialog.propTypes = {
    openPremiumDialog: PropTypes.bool.isRequired,
    setOpenPremiumDialog: PropTypes.func.isRequired,
    customerInfo: PropTypes.object.isRequired,
    setShowNotification: PropTypes.func.isRequired,
    setNotificationContent: PropTypes.func.isRequired
}

export default PremiumsDialog;