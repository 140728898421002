import {
    Box,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@mui/material';
import { getCustomerTransactions } from 'api/customers';
import MDTypography from 'components/MDTypography';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const TransactionsDialog = ({ openTransactionsDialog, setOpenTransactionsDialog, customer_id }) => {
    const [transactionsLoading, setTransactionsLoading] = useState(false);
    const [transactions, setTransactions] = useState([]);

    useEffect(() => {
        if (openTransactionsDialog) {
            setTransactionsLoading(true);

            getCustomerTransactions(customer_id)
                .then(transactions => setTransactions(transactions ?? []))
                .catch(error => console.error("Failed to fetch transactions: ", error))
                .finally(() => setTransactionsLoading(false));
        }
    }, [openTransactionsDialog, customer_id]);

    const getStatusColor = (status) => {
        switch (status) {
            case 'accepted':
                return 'green';
            case 'pending':
                return 'orange';
            case 'rejected':
                return 'red';
            default:
                return 'gray';
        }
    };

    const handleTransactionDialogClose = () => {
        setOpenTransactionsDialog(false);
        setTransactions([]);
    };

    return (
        <Dialog
            open={openTransactionsDialog}
            onClose={handleTransactionDialogClose}
            aria-labelledby="transactions-dialog"
            maxWidth="lg"
            fullWidth
        >
            <DialogTitle>User Transactions</DialogTitle>
            <DialogContent>
                {transactionsLoading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 300 }}>
                        <CircularProgress />
                    </Box>
                ) : transactions.length > 0 ? (
                    <TableContainer component={Paper}>
                        <Table aria-label="Transactions table">
                            <TableHead sx={{ display: 'contents' }}>
                                <TableRow>
                                    <TableCell>Date</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell align="right">Amount</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {transactions.map((transaction) => (
                                    <TableRow key={transaction.w_id}>
                                        <TableCell>
                                            {new Date(transaction.updatedAt).toLocaleString()}
                                        </TableCell>
                                        <TableCell>
                                            <Box
                                                sx={{
                                                    display: 'inline-block',
                                                    padding: '2px 8px',
                                                    borderRadius: '12px',
                                                    backgroundColor: getStatusColor(transaction.status),
                                                    color: 'white !important',
                                                    textTransform: 'capitalize',
                                                }}
                                            >
                                                {transaction.status}
                                            </Box>
                                        </TableCell>
                                        <TableCell
                                            align="right"
                                            sx={{
                                                color: transaction.type === 'withdraw' ? 'error.main' : 'success.main',
                                                fontWeight: 'bold'
                                            }}
                                        >
                                            {transaction.type === 'withdraw' ? '-' : '+'} {transaction.amount} USDC
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 100 }}>
                        <MDTypography>No transactions found.</MDTypography>
                    </Box>
                )}
            </DialogContent>
        </Dialog>
    );
};

TransactionsDialog.propTypes = {
    openTransactionsDialog: PropTypes.bool.isRequired,
    setOpenTransactionsDialog: PropTypes.func.isRequired,
    customer_id: PropTypes.string.isRequired,
};

export default TransactionsDialog;