import axiosInstance from "./config";

export const customersInfo = async () => {
  try {
    const res = await axiosInstance.get("/agents/customers");
    console.log(res.data)
    return res.data;
  } catch (error) {
    console.error(error.response.data);
  }
};

export const getPremiumMovies = async (customerId) => {
  try {
    const res = await axiosInstance.get("agents/premiums", { params: { customerId } });
    console.log(res.data);
    return res;
  } catch (error) {
    console.error(error.response.data);
  }
};

export const getAssignedPremiumMovies = async (customerId) => {
  try {
    const res = await axiosInstance.get("agents/premiums/assigned", { params: { customerId } });
    console.log(res.data);
    return res;
  } catch (error) {
    console.error(error.response.data);
  }
};

export const deleteAssignedPremiumMovies = async (customerId, movieIds) => {
  try {
    const res = await axiosInstance.post("agents/premiums/assigned", { customerId, movieIds });
    console.log(res.data);
    return res;
  } catch (error) {
    console.error(error.response.data);
  }
};

export const setPremiumMovies = async (customerId, startIndex, movieIds) => {
  try {
    const res = await axiosInstance.post("agents/premiums", { customerId, startIndex, movieIds });
    console.log(res.data)
    return res;
  } catch (error) {
    console.error(error.response.data);
  }
};

export const updateCustomerInfo = async (data) => {
  try {
    const res = await axiosInstance.post("agents/customers", data);
    console.log(res.data)
    return res;
  } catch (error) {
    console.error(error.response.data);
  }
};

export const getPendingWithdrawal = async (count) => {
  try {
    const res = await axiosInstance.get(`/agents/withdrawals${count ? '?count=true' : ''}`);
    console.log(res.data)
    return res.data;
  } catch (error) {
    console.error(error.response.data);
  }
};

export const getCustomerTransactions = async (c_id) => {
  try {
    const res = await axiosInstance.get(`/agents/transactions?c_id=${c_id}`);
    console.log(res.data)
    return res.data;
  } catch (error) {
    console.error(error.response.data);
  }
};

export const updateWithdrawalStatus = async (data) => {
  try {
    const res = await axiosInstance.post("agents/withdrawal/status", data);
    console.log(res.data)
    return res;
  } catch (error) {
    console.error(error.response.data);
  }
};

export const resetSession = async (customerId) => {
  try {
    const res = await axiosInstance.post("agents/session/reset", { customerId });
    console.log(res.data)
    return res;
  } catch (error) {
    console.error(error.response.data);
  }
};