// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDProgress from "components/MDProgress";
import PropTypes, { number } from "prop-types";

// Images
import team2 from "assets/images/team-2.jpg";
import { useEffect, useState } from "react";
import ActionMenu from "components/ActionMenu";
import { customersInfo, resetSession } from "api/customers";
import MDButton from "components/MDButton";

export default function data() {
  const Customer = ({ image, name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      {/* <MDAvatar src={image} name={name} size="sm" /> */}
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const Parent = ({ roleName, userName, userId }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="button" fontWeight="medium">
        {userName}
      </MDTypography>
      <MDTypography display="block" variant="caption" color="text">
        {roleName}
      </MDTypography>
      <MDTypography variant="caption" color="text">
        ID: {userId}
      </MDTypography>
    </MDBox>
  );

  const ProgressOrReset = ({ currentIndex, totalLimit, onReset }) => {
    const isCompleted = currentIndex === totalLimit;

    return (
      <MDBox display="flex" alignItems="center">
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {currentIndex} / {totalLimit}
        </MDTypography>
        {isCompleted ? (
          <MDButton variant="contained" color="success" onClick={onReset}>
            Reset Session
          </MDButton>
        ) : (
          <>
            <MDBox ml={0.5} width="9rem">
              <MDProgress variant="gradient" color="success" value={(currentIndex / totalLimit) * 100} />
            </MDBox>
          </>
        )}
      </MDBox>
    );
  };

  Customer.propTypes = {
    image: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  };
  Parent.propTypes = {
    roleName: PropTypes.string.isRequired,
    userName: PropTypes.string.isRequired,
    userId: PropTypes.number.isRequired,
  };
  ProgressOrReset.propTypes = {
    currentIndex: PropTypes.number.isRequired,
    totalLimit: PropTypes.number.isRequired,
    onReset: PropTypes.func.isRequired,
  };

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const getData = async () => {
    try {
      const result = await customersInfo();
      console.log(result);
      setData(result);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData(); // Call the async function
  }, []); // Empty dependency array means this useEffect runs once when the component mounts

  const handleReset = async (customerId) => {
    try {
      const result = await resetSession(customerId);
      if (result.status === 200) {
        getData();
      }
    } catch (error) {
      console.error("Error while resetting Session: ", error)
    }
  };

  if (loading) {
    return { columns: [], rows: [], loading, refetchData: () => { } };
  }

  if (error) {
    return { columns: [], rows: [], error };
  }

  return {
    columns: [
      { Header: "customer", accessor: "customer", align: "left" },
      { Header: "number", accessor: "number", align: "left" },
      { Header: "balance", accessor: "balance", align: "left" },
      { Header: "commission", accessor: "commission", align: "left" },
      { Header: "parent", accessor: "parent", align: "left" },
      { Header: "referral", accessor: "referral", align: "left" },
      { Header: "trust points", accessor: "trust_points", align: "left" },
      { Header: "Referrer username", accessor: "referrer", align: "left" },
      { Header: "orders", accessor: "orders", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ],

    rows: data.map((customer) => ({
      customer: {
        component: (<Customer image={team2} name={`${customer.first_name} ${customer.last_name || ''}`} email={customer.username} />),
        text: `${customer.first_name} ${customer.last_name || ''} ${customer.username}`
      },
      number: {
        component: (<MDTypography variant="button" color="text" fontWeight="medium">{customer.number || ""}</MDTypography>),
        text: `${customer.number || ""} `
      },
      balance: {
        component: (<MDTypography variant="button" color="text" fontWeight="medium">USDC {parseInt(customer.negative_amount) < 0 ? customer.negative_amount : customer.wallet_amount}</MDTypography>),
        text: customer.wallet_amount.toString()
      },
      referral: {
        component: (<MDTypography variant="button" color="text" fontWeight="medium">{customer.invitationCode}</MDTypography>),
        text: customer.invitationCode.toString()
      },
      trust_points: {
        component: (<MDTypography variant="button" color="text" fontWeight="medium">{customer.trust_point}</MDTypography>),
        text: customer.trust_point.toString()
      },
      referrer: {
        component: (<MDTypography variant="button" color="text" fontWeight="medium">{customer?.referrer?.username || ""}</MDTypography>),
        text: `${customer?.referrer?.username || ""}`
      },
      commission: {
        component: (<MDTypography variant="button" color="text" fontWeight="medium">USDC {customer.currentSession.total_profit}</MDTypography>),
        text: customer.currentSession.total_profit.toString()
      },
      parent: {
        component: (<Parent roleName={customer.agent.role_name} userName={customer.agent.username} userId={customer.agent.agent_id} />),
        text: `${customer.agent.role_name} ${customer.agent.username} ${customer.agent.agent_id}`
      },
      orders: {
        component: (<ProgressOrReset currentIndex={customer.currentSession.current_index} totalLimit={customer.tier.tier_limit} onReset={() => handleReset(customer.customer_id)} />),
        text: `${customer.currentSession.current_index} ${customer.tier.tier_limit}`
      },
      action: {
        component: (<ActionMenu customerInfo={customer} refetchData={() => getData()} />),
        text: ""
      },
    })),

    loading: false,

    refetchData: () => getData()
  };
}
